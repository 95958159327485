import React from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import UserPanel from 'components/user-panel/user-panel';
import { Template } from 'devextreme-react/core/template';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltLeft } from '@fortawesome/pro-solid-svg-icons'
import { useHistory } from 'react-router'
import './header.scss';

export default ({ user, menuToggleEnabled, title, toggleMenu, userMenuItems, parentPath }) => {

    const history = useHistory()

    function goBack() {
        history.push(parentPath);
    }

    return (
        <header className={'header-component'}>
            <Toolbar className={'header-toolbar'}>
                {
                    parentPath && parentPath.length > 0 ?
                        (
                            <Item visible={true} location={'before'} widget={'dxButton'} cssClass={'menu-button'}>
                                <FontAwesomeIcon icon={faLongArrowAltLeft} size={'2x'} color={'#03A9F4'} onClick={goBack} />
                            </Item>
                        )
                        :
                        (
                            <Item visible={menuToggleEnabled} location={'before'} cssClass={'menu-button'}>
                                <Button icon="menu" stylingMode="text" onClick={toggleMenu} />
                            </Item>
                        )
                }
                <Item location={'before'} cssClass={'header-title'} text={title} visible={!!title} />
                <Item
                    location={'after'}
                    locateInMenu={'auto'}
                    menuItemTemplate={'userPanelTemplate'}
                >
                    <Button
                        className={'user-button authorization'}
                        height={'100%'}
                        stylingMode={'text'}
                    >
                        <UserPanel menuItems={userMenuItems} menuMode={'context'} user={user} />
                    </Button>
                </Item>
                <Template name={'userPanelTemplate'}>
                    <UserPanel menuItems={userMenuItems} menuMode={'list'} user={user} />
                </Template>

            </Toolbar>
        </header>
    );
};