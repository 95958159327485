import React from "react";
import { Button } from 'devextreme-react/button';
import "./confirm-button.scss";

export default ({ loadNumber, onConfirmClick }) => {

    return (
        <Button className="confirm-button-button" icon="check"
            text="Confirm"
            onClick={() => onConfirmClick(loadNumber)}
        />
    );
}



