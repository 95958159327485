import React, { useState, useEffect } from 'react';
import TextBox from 'devextreme-react/ui/text-box';
import Button from 'devextreme-react/ui/button';
import './keyword-search.scss';

const KeywordSearch = ({ keyword, onSearch, placeholder }) => {
  const [searchQuery, setSearchQuery] = useState(null)
  const sessionKey = 'preferred-route-load-number'

  useEffect(() => {
    if (searchQuery == null && sessionStorage.getItem(sessionKey) != null) {
      setSearchQuery(sessionStorage.getItem(sessionKey))
      onSearch(sessionStorage.getItem(sessionKey))
    }
  }, [])

  useEffect(() => {
    if (keyword) {
      setSearchQuery(keyword)
    } else {
      setSearchQuery(null)
    }
  }, [keyword])

  const buttonClickHandler = () => {
    sessionStorage.setItem(sessionKey, searchQuery)
    onSearch(searchQuery)
  }

  const enterKeyHandler = () => {
    sessionStorage.setItem(sessionKey, searchQuery)
    onSearch(searchQuery)
  }

  return (
    <div className="keyword-search-container">
      <table className="keyword-search-table">
        <tbody>
          <tr>
            <td className="keyword-search-button-container">
              <Button type="default" width={36} height={36} icon="icon ion-android-search" text="" onClick={buttonClickHandler} />
            </td>
            <td className="keyword-search-textbox-container">
              <TextBox value={searchQuery} placeholder={placeholder} onValueChanged={(e) => setSearchQuery(e.value)} onEnterKey={enterKeyHandler} showClearButton={true} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default KeywordSearch;
