import { FC } from 'react'
import React from 'react';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import { Route, Switch, useHistory } from 'react-router-dom';
import Logout from './Logout';
import Content from '../../Content';
import { useScreenSizeClass } from '../../utils/media-query';
import { OktaAuth, Token, TokenManagerError, TokenManagerErrorEventHandler, toRelativeUrl, TokenManagerEventHandler, UserClaims } from '@okta/okta-auth-js'
import { authConfig, authParams, BASE_URL, LOGIN_CALLBACK_URI } from './OktaConfig';

export const oktaAuth: OktaAuth = new OktaAuth(authConfig);

oktaAuth.tokenManager.on('expired', function (key: string, expiredToken: Token): void {
    // extra requirement: user must have valid Okta SSO session
    oktaAuth.token?.getUserInfo().then((user: UserClaims) => {
        if (!user) {
            if (key == "accessToken") {
                oktaAuth.tokenManager.renew(key);
            }
            else {
                loginWithRedirect()
            }
        }
    })
} as TokenManagerEventHandler)

oktaAuth.tokenManager.on('renewed', function (key: string, newToken: Token, oldToken: Token): void {
    console.log('Token with key ', key, ' has been renewed');
} as TokenManagerEventHandler)

oktaAuth.tokenManager.on('error', function (err: TokenManagerError): void {
    loginWithRedirect()
} as TokenManagerErrorEventHandler)

const interval = setInterval(async () => {
    // Renew 60 seconds before expiry

    try {
        const user: UserClaims | undefined = await oktaAuth.token.getUserInfo()
    }
    catch (error) {
        console.log("Error trying to get user claims: ", error);
    }

}, 60000);

const loginWithRedirect = async () => {
    window.location.href = `${BASE_URL}/oauth2/default/v1/authorize?${authParams.join('&')}`
}

export const SecureRouter: FC = (props) => {

    const history = useHistory();
    const screenSizeClass = useScreenSizeClass();

    const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string): Promise<void> => {
        history.replace(toRelativeUrl(originalUri || '/', window.location.origin))
    }

    return (
        <div className={`app ${screenSizeClass}`}>
            <Security
                restoreOriginalUri={restoreOriginalUri}
                oktaAuth={oktaAuth}
            >
                <Switch>
                    <Route path="/logout" exact={true} component={Logout} />
                    <Route path={`${LOGIN_CALLBACK_URI}`} component={() => {
                        return (
                            <LoginCallback
                                errorComponent={() => {
                                    console.log("Error with OKTA in SecureRouter login callback.")
                                    return null
                                }}
                            />
                        )
                    }} />
                    <SecureRoute path="/" component={Content} />
                </Switch>
            </Security>
        </div>
    );
}

export default SecureRouter