import React, { FC } from "react";
import ButtonComponent from "../ButtonComponent/ButtonComponent";
import "./CreateExpenseHeader.scss";

interface Props {
  handleBackNavigation?: () => void;
  handleSubmitExpense?: (e: any) => void;
  expenseTable?: any;
}

const CreateExpenseHeader: FC<Props> = ({
  handleBackNavigation,
  handleSubmitExpense,
  expenseTable,
}) => {
  return (
    <div
      className="header-row"
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        margin: "20px",
      }}
    >
      <ButtonComponent
        handleClick={handleBackNavigation}
        type={"success"}
        stylingMode={"outlined"}
        text={"BACK"}
        width={120}
      />
      <ButtonComponent
        handleClick={handleSubmitExpense}
        type={"default"}
        stylingMode={"contained"}
        text={"SUBMIT"}
        width={150}
        id="submit-btn"
        disabled={expenseTable && expenseTable.length === 0}
      />
    </div>
  );
};

export default CreateExpenseHeader;
