import React, { Fragment } from "react";
import "./confirm-box.scss";

export default ({ confirmationNumber }) => {
    return (
        <Fragment>
            <div>
                <div className="confirm-box-borders columns2">
                    <div>
                        <div className="confirm-box-left">Confirmation Number</div>
                    </div>
                    <div>
                        <div className="confirm-box-center">:</div>
                    </div>
                    <div>
                        <div className="confirm-box-right">{confirmationNumber}</div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

