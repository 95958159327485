import React, { Fragment } from "react";
import "./stop-header-box.scss";
import { faThermometerHalf } from '@fortawesome/pro-solid-svg-icons' //more icons at: https://fontawesome.com/icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default ({ headerTitle, temperature }) => {

    return (
        <Fragment>
            {
                <div className={'order-heading-item-container'} >
                    <div className="columns2">
                        <div className={'order-heading-item-title'} >{headerTitle}</div>
                        <div className={'order-heading-item-type'}>
                            <div className="columns2">
                                <FontAwesomeIcon icon={faThermometerHalf} color="Red" size="2x" />
                                <div className="order-heading-item-type-desc-text">{temperature}</div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Fragment>
    );
};
