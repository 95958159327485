import React, { FC } from 'react';
import 'devextreme/data/odata/store';
import { HeaderBox, Stops, Totals, Title } from 'components/load-confirmation'
import { Stop } from 'types'

type Props = {
    stops: Stop[],
    weight: string,
    cube: string,
    pieceCount: string,
    lineItems: string,
    loadNumber: string
}

const AppointmentDetail: FC<Props> = ({ stops, weight, cube, pieceCount, lineItems, loadNumber }) => {

    return (
        <div className={`main-container-item-container`} >
            <HeaderBox headerTitle={'Appointment Details'} changed={false} />
            <div className={"left-margins-double right-margins-double"} >
                <Stops loadNumber={loadNumber} stops={stops} />
            </div>
            <Title title="Totals" />
            <div className={"left-margins-double right-margins-double"} >
                <Totals weight={weight} cube={cube} pieceCount={pieceCount} items={lineItems} />
            </div>
        </div>
    );

};

export default AppointmentDetail