import React, { FC } from "react";
import { saleAmountHeaderFilter } from "../../helpers/ApplyFilters";
import DataGrid, { Column, HeaderFilter } from "devextreme-react/data-grid";
import { OverLimitExpenseResponse, ValidateExpenseResponse } from "types";
import "./ValidationDataGrid.scss";

interface Props {
  dataGridValue?: any;
  onRowRemoving?: (e: any) => void;
  onRowRemoved?: (e: any) => void;
  canDisplayIcons?: (e: any) => boolean;
  handleEditDenyModal?: (e: any) => void;
  showFilterRow?: boolean;
  currentFilter?: any;
  showHeaderFilter?: boolean;
  calculateFilterExpression?: (
    value: any,
    selectedFilterOperations: any,
    target: any
  ) => void;
  orderHeaderFilter?: (data: any) => void;
  saleAmountEditorOptions?: any;
  handleImagePreview?: (e: any) => void;
  positionOptions?: any;
  popupVisible?: boolean;
  hideInfo?: (e: any) => void;
  closeButtonOptions?: any;
  canShowReceipt?: boolean;
  baseImageValue?: string;
  canShowSubmitExpense?: boolean;
  duplicateExpenseData?: ValidateExpenseResponse[];
  overlimitExpenseData?: OverLimitExpenseResponse[];
}

const ValidationDataGrid: FC<Props> = ({
  dataGridValue,
  duplicateExpenseData,
  overlimitExpenseData,
}) => {
  return (
    <>
      <div
        id="validationPopupDiv"
        style={{ maxHeight: "100%", overflowY: "auto" }}
      >
        <h6>Potential Duplicate Expenses</h6>
        <DataGrid
          id="validateGridContainer"
          ref={(ref) => {
            dataGridValue = ref;
          }}
          dataSource={duplicateExpenseData}
          keyExpr="expenseType.id"
          showBorders={true}
          rowAlternationEnabled={true}
          hoverStateEnabled={false}
          columnHidingEnabled={true}
        >
          <Column
            dataField="requestedAmount"
            caption="Requested Amount"
            alignment="right"
            dataType="number"
            format={{ type: "currency", precision: 2 }}
          >
            <HeaderFilter dataSource={saleAmountHeaderFilter} />
          </Column>
          <Column dataField="expenseType.description" caption="Expense Type">
            <HeaderFilter allowSearch={true} />
          </Column>
          <Column
            dataField="receiptDate"
            caption="Receipt Date"
            alignment="right"
            dataType="datetime"
            format="M/d/yyyy"
          />
          <Column
            dataField="expenseStatus.description"
            caption="Expense Status"
          >
            <HeaderFilter allowSearch={true} />
          </Column>
        </DataGrid>
        <h6>Over Limit Expenses</h6>
        <DataGrid
          id="ValidateGridContainer"
          ref={(ref) => {
            dataGridValue = ref;
          }}
          dataSource={overlimitExpenseData}
          keyExpr="id"
          showBorders={true}
          rowAlternationEnabled={true}
          hoverStateEnabled={false}
          columnHidingEnabled={true}
        >
          <Column
            dataField="requestedAmount"
            caption="Requested Amount"
            alignment="right"
            dataType="number"
            format={{ type: "currency", precision: 2 }}
          >
            <HeaderFilter dataSource={saleAmountHeaderFilter} />
          </Column>
          <Column dataField="expenseType.description" caption="Expense Type">
            <HeaderFilter allowSearch={true} />
          </Column>
          <Column
            dataField="receiptDate"
            caption="Receipt Date"
            alignment="right"
            dataType="datetime"
            format="M/d/yyyy"
          />
          <Column
            dataField="expenseStatus.description"
            caption="Expense Status"
          >
            <HeaderFilter allowSearch={true} />
          </Column>
        </DataGrid>
      </div>
    </>
  );
};

export default ValidationDataGrid;
