import React from 'react';

const createAddresss = (stop) => {
  var retVal = '';
  if (stop?.address1) {
    retVal = `${retVal}${stop.address1}, `;
  }
  retVal = `${retVal}${stop?.city ?? "Unknown City"} ${stop?.state ?? "Unknown State"}, ${stop?.zip ?? "Unknown ZIP"}`;
  return retVal;
};

const LegAddress = ({ label, stop }) => {
  return (
    <table className="leg-table-header-table">
      <tbody>
        <tr>
          <td rowSpan="2" className="leg-table-header-to">{label}</td>
          <td className="leg-table-header-to-name">{stop?.companyName ?? 'Unknown Company'}</td>
        </tr>
        <tr>
          <td className="leg-table-header-to-address">{createAddresss(stop)}</td>
        </tr>
      </tbody>
    </table>
  );
};
export default LegAddress;
