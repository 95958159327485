import { OktaAuth, OktaAuthOptions, UserClaims, AuthState } from '@okta/okta-auth-js'

export const SCOPES: string[] = ['openid', 'profile', 'email']
export const BASE_URL: string = 'https://dotfoods.okta.com'
export const CLIENT_ID: string = `${process.env.REACT_APP_OKTA_CLIENT_ID}`
export const ISSUER: string = `${BASE_URL}/oauth2/default`
export const LOGIN_CALLBACK_URI: string = '/implicit/callback'
export const LOGIN_REDIRECT_URI: string = `${window.location.origin}${process.env.PUBLIC_URL}${LOGIN_CALLBACK_URI}`
export const POST_LOGOUT_REDIRECT_URI: string = `${window.location.origin}${process.env.PUBLIC_URL}`

export const authConfig: OktaAuthOptions = {
    issuer: ISSUER,
    clientId: CLIENT_ID,
    redirectUri: LOGIN_REDIRECT_URI,
    scopes: SCOPES,
    tokenManager: {
        syncStorage: true,
        storage: 'sessionStorage',
        autoRenew: true,
    },
    pkce: true,
    transformAuthState: async (oktaAuth: OktaAuth, authState: AuthState): Promise<AuthState> => {
        if (authState && authState.isAuthenticated) {
            return authState
        }

        // extra requirement: user must have valid Okta SSO session
        const user: UserClaims | undefined = await oktaAuth.token.getUserInfo()
        authState.isAuthenticated = !!user
        return authState
    }
} as OktaAuthOptions

export const authParams = [
    `client_id=${CLIENT_ID}`,
    `redirect_uri=${encodeURIComponent(LOGIN_REDIRECT_URI)}`,
    `scope=${SCOPES.join('%20')}`,
    `response_type=code`,
    `code_challenge_method=S256`
]

