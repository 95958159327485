import React, { FC, Fragment } from 'react';
import 'devextreme/data/odata/store';
import { AppointmentDetail, OrderTotals } from 'components/load-confirmation'
import { StopDetailsModel, Stop } from 'types'

type Props = {
    stopDetails: StopDetailsModel,
    loadNumber: string
}

const StopDetail: FC<Props> = ({ stopDetails, loadNumber }) => {

    // Only display the first stop for the details.  We are passing in
    // an array because the Stops component expects one.  This will
    // allow code reuse instead of creating a new component.
    var singleStop: Stop[] = [];
    if (stopDetails) {
        var stops = stopDetails.stops;
        if (stops && stops.length > 0) {
            singleStop.push(stops[0]);
        }
    }

    return (
        (stopDetails && stopDetails.appointmentDetails) ?
            <Fragment>
                <AppointmentDetail
                    cube={stopDetails.appointmentDetails.cube}
                    stops={singleStop}
                    pieceCount={stopDetails.appointmentDetails.pieceCount}
                    lineItems={stopDetails.appointmentDetails.lineItems}
                    weight={stopDetails.appointmentDetails.weight}
                    loadNumber={loadNumber}
                />
                {
                    stopDetails.stops.map((stop, index) => (
                        <OrderTotals
                            confirmationNumber={stop.order.confirmationNumber}
                            orderNumber={stop.orderNumber}
                            temperature={stop.order.temperature}
                            key={stop.id}
                            weight={stop.order.weight}
                            cube={stop.order.cube}
                            pieceCount={stop.order.pieceCount}
                            items={stop.order.lineItems}
                        />
                    ))
                }
            </Fragment>
            :
            <Fragment />
    );

};

export default StopDetail