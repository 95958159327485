import React, { FC } from 'react';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import './action-button.scss'
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type Props = {
    icon: IconProp,
    iconColor?: string,
    iconSize?: FontAwesomeIconProps["size"],
    buttonColor?: string,
    buttonText?: string,
    enabled?: boolean,
    buttonTextColor?: string,
    borderColor?: string,
    buttonWidth?: string,
    circular?: boolean,
    onClick?: () => void,
    id?: string,
    buttonClass?: string,
    buttonStyle?: any
}

export const ActionButton: FC<Props> = ({ icon, iconColor = 'white', iconSize = '1x', buttonColor = 'white', buttonText = '', enabled = true, buttonTextColor = 'White', borderColor = 'none', buttonWidth = '110px', circular = false, onClick = () => { }, id = "",
            buttonStyle = {
                backgroundColor: buttonColor,
                border: borderColor,
                width: buttonWidth,
                height: circular ? buttonWidth : '40px',
                borderRadius: circular ? '50%' : '15px'
            }
        }
    ) => {

    return (
        <div id={id} className={'action-container'} >
            <div className={`action-button ${enabled ? '' : 'disabled'}`} style={buttonStyle} onClick={onClick}>
                <FontAwesomeIcon icon={icon} color={iconColor} size={iconSize} />
                {buttonText &&
                    <div className={'action-text'} style={{ color: buttonTextColor }}>{buttonText}</div>
                }
            </div>
        </div>
    )
}