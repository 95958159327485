
export default () => {

    const getIntValue = (name: string): number => parseInt(getStringValue(name) ?? '-1')
    const getStringValue = (name: string): string | null => new URLSearchParams(window.location.search).get(name)

    return {
        getIntValue: getIntValue,
        getStringValue: getStringValue
    }
}