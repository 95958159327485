import React from 'react';
import { PreferredFuelStops, Directions, Map, CopilotDisplay } from 'components';

const RenderTabs = ({ selectedIndex, stops, preferredFuelStops, fuelDCLocations, routeId, directionsReport, loadNumber }) => {
  switch (selectedIndex) {
    case 1:  // PREFERRED FUEL LOCATIONS
      return <PreferredFuelStops stops={preferredFuelStops} />
    case 2:  // TURN BY TURN DIRECTIONS
      return <Directions report={directionsReport} stops={stops} />
    // case 3:  // TURN BY TURN DIRECTIONS
    //   return <CopilotDisplay loadNumber={loadNumber} />
    default: // MAP
      return <Map routeId={routeId} stops={stops} preferredFuelStops={preferredFuelStops} fuelDCLocations={fuelDCLocations} />
  }
}
export default RenderTabs