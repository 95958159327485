import React, { Fragment } from "react";
import NumberFormat from 'react-number-format';
import "./load-totals.scss";

export default ({ weight, cube, pieceCount, items, mileage=null }) => {
    return (
        <Fragment>
            {
                <div className="load-totals-columns bottom-margins">
                    <div>
                        <div className="columns2">
                            <div>
                                <div className="load-totals-our-text">Weight</div>
                                <div className="load-totals-our-text">Cube</div>
                                {mileage &&
                                    <div className="load-totals-our-text">Miles</div>
                                }
                            </div>
                            <div>
                                <div className="load-totals-our-text">:</div>
                                <div className="load-totals-our-text">:</div>
                                {mileage &&
                                    <div className="load-totals-our-text">:</div>
                                }
                            </div>
                            <div>
                                <div className="load-totals-our-totals">
                                    <NumberFormat value={weight} displayType='text' thousandSeparator={true} />
                                </div>
                                <div className="load-totals-our-totals">
                                    <NumberFormat value={cube} displayType='text' thousandSeparator={true} />
                                </div>
                                {mileage &&
                                    <div className="load-totals-our-totals">
                                        <NumberFormat value={mileage} displayType='text' thousandSeparator={true} />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div className="columns2">
                                <div>
                                    <div className="load-totals-our-text">Pieces</div>
                                    <div className="load-totals-our-text">Line Items</div>
                                </div>
                                <div>
                                    <div className="load-totals-our-text">:</div>
                                    <div className="load-totals-our-text">:</div>
                                </div>
                                <div>
                                    <div className="load-totals-our-totals">
                                        <NumberFormat value={pieceCount} displayType='text' thousandSeparator={true} />
                                    </div>
                                    <div className="load-totals-our-totals">
                                        <NumberFormat value={items} displayType='text' thousandSeparator={true} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Fragment>
    );
};

