import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SummaryHeader = ({ title, icon, color }) => {

    return (
        <React.Fragment>
            <div className="title-card">
                <table className="header-table">
                    <tbody>
                        <tr>
                            <td className="header-button"><FontAwesomeIcon icon={icon} size={'5x'} color={color} /></td>
                            <td className="header-text"><h4>{title}</h4></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    )
}

export default SummaryHeader;