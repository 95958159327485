import React from "react";
import Box, { Item } from "devextreme-react/box";
import "./msg-box.scss";
import { faStickyNote } from '@fortawesome/pro-solid-svg-icons' //more icons at: https://fontawesome.com/icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default ({ message }) => {

    var friendlyMessage = message;
    if (message == null || message.length <= 0) {
        friendlyMessage = "No load comment."
    }

    return (
        <Box width={"100%"} className="rect msg-box-our-color msg-borders ">
            <Item ratio={1} >
                <div className={"msg-box-sticky-note-div"}>
                    <FontAwesomeIcon icon={faStickyNote} className={"msg-box-sticky-note"} size={"1x"} />
                </div>
            </Item>
            <Item ratio={5.5}>
                <div className={"msg-box-our-msg"}>{friendlyMessage}</div>
            </Item>
        </Box>
    );
};