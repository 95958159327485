import React from 'react';
import { ReportLegs } from '.';
import ScrollView from 'devextreme-react/scroll-view';
import './directions.scss'

const Directions = ({ report, stops }) => {
  return (
    <div className="card">
      {report && (
        <ScrollView
          id="directionsScrollview"
          scrollByContent={true}
          bounceEnabled={false}
          showScrollbar="always"
          scrollByThumb={true}
        >
          {report?.reportLegs?.length > 0 ?
            <div id="directions" className="container-content">
              <ReportLegs legs={report.reportLegs} stops={stops} />
            </div>
            :
            <div className="directions-not-available-container">
              <div className="directions-not-available-image icon ion-map"></div>
              <div className="directions-not-available-text">No Directions Available</div>
            </div>
          }

        </ScrollView>
      )}
    </div>
  );
};
export default Directions;
