import React from 'react';
import './home.scss';
import appInfo from '../../app-info';
import { faTruck } from '@fortawesome/pro-duotone-svg-icons' //more icons at: https://fontawesome.com/icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default () => (
  <React.Fragment>
    <h2 className={'content-block'}>Home</h2>
    <div className={'content-block'}>
      <div className={'dx-card responsive-paddings'}>
        <FontAwesomeIcon icon={faTruck} color={'#0066A4'} size={'2x'}/>
        <p>
          Welcome to the <b>{appInfo.title}</b> app!
        </p>
      </div>
    </div>
  </React.Fragment>
);
