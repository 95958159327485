import React, { useState, useEffect } from 'react';
import Tabs from 'devextreme-react/tabs';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import '@trimblemaps/trimblemaps-js/trimblemaps.css';
import './map.scss'

const Map = ({ routeId, stops, preferredFuelStops, fuelDCLocations }) => {
    const [mapStyle, setMapStyle] = useState(0);
    const tabs = [
        {
            id: 0,
            text: TrimbleMaps.Common.Style.TRANSPORTATION,
            icon: 'icon ion-android-car',
        },
        {
            id: 1,
            text: TrimbleMaps.Common.Style.SATELLITE,
            icon: 'icon ion-android-globe',
        },
    ];

    TrimbleMaps.APIKey = process.env.REACT_APP_MAPPING_API_KEY;

    useEffect(() => {
        // DIRECTIONS
        let map = new TrimbleMaps.Map({
            container: 'map',
            center: [-90.755043, 39.976473], // DOT HQ
            style: tabs.find((element) => element.id === mapStyle).text,
        });

        map.scrollZoom.disable();

        let nav = new TrimbleMaps.NavigationControl();
        nav.showCompass = false;
        map.addControl(nav, 'bottom-right');

        // ADD PLANNED ROUTE
        let plannedRoute = createRoute(`planned-route-${routeId}`, stops, false);

        map.on('load', function () {
            // ADD PLANNED ROUTE TO MAP
            plannedRoute.addTo(map);

            map.addSource('poiSource', convertPreferredFuelStopsToGeoJSON(preferredFuelStops.concat(fuelDCLocations), null));

            // ADD PREFERRED FUEL STOP MARKERS TO MAP
            map.loadImage(`${process.env.PUBLIC_URL}/fuel.png`, function (error, image) {
                map.addImage('poi-marker-icon', image);
                map.addLayer({
                    id: 'poiPoints',
                    type: 'symbol',
                    source: 'poiSource',
                    layout: {
                        'icon-image': 'poi-marker-icon',
                        'icon-size': 1,
                    },
                });

                map.on('click', 'poiPoints', function (evt) {
                    let popupLocation = evt.features[0].geometry.coordinates.slice();
                    let popupContent = `<div class="poi-popup-header">${evt.features[0].properties.name}</div>`;
                    popupContent = (evt.features[0].properties.price != undefined && evt.features[0].properties.price != null && evt.features[0].properties.price != 'null') ? `${popupContent}<div>Price: $${evt.features[0].properties.price.toFixed(2)}</div>` : `${popupContent}<div>Price: unavailable</div>`
                    popupContent = `${popupContent}<div class="poi-popup-address">${evt.features[0].properties.street}</div>`;
                    popupContent = `${popupContent}<div class="poi-popup-address">${evt.features[0].properties.city}, ${evt.features[0].properties.state} ${evt.features[0].properties.zip}</div>`;

                    new TrimbleMaps.Popup().setLngLat(popupLocation).setHTML(popupContent).addTo(map);
                });

                map.on('mouseenter', 'poiPoints', function () {
                    map.getCanvas().style.cursor = 'pointer';
                });

                map.on('mouseleave', 'poiPoints', function () {
                    map.getCanvas().style.cursor = '';
                });
            });

            // CENTER ON PLANNED ROUTE
            plannedRoute.frameRoute();
        });
    }, [preferredFuelStops, routeId, stops, mapStyle]);

    const createRoute = (routeId, stops, isDraggable) => {
        return new TrimbleMaps.Route(createRouteOptions(routeId, stops, isDraggable));
    };

    const createRouteOptions = (routeId, stops, isDraggable) => {
        return {
            routeId: routeId,
            afSetIDs: [2104, 10337060, 10317131, 10309683, 10309688, 10328266, 10308064, 10326847],
            stops: convertStopsToTrimbleLngLat(stops),
            tollDiscourage: false,
            highwayOnly: false,
            bordersOpen: true,
            overrideRestrict: false,
            routeType: TrimbleMaps.Common.RouteType.PRACTICAL,
            hazMatType: TrimbleMaps.Common.HazMatType.NONE,
            DistanceUnits: TrimbleMaps.Common.DistanceUnit.MILES,
            fuelUnits: TrimbleMaps.Common.FuelUnit.GALLONS,
            trkUnits: TrimbleMaps.Common.TruckUnit.ENGLISH,
            trkAxles: 5,
            trkWeight: 80000,
            trkWidth: '8\'6"',
            trkLength: '53\'',
            trkHeight: '13\'6"',
            isDraggable: isDraggable
        }
    };

    const convertStopsToTrimbleLngLat = (stops) => {
        let retVal = [];
        if (stops && Array.isArray(stops)) {
            stops.forEach((stop) => {
                retVal.push(new TrimbleMaps.LngLat(stop.lon, stop.lat));
            });
        }
        return retVal;
    };

    const convertPreferredFuelStopsToGeoJSON = (preferredFuelStops, stops) => {
        let features = [];

        if (preferredFuelStops && preferredFuelStops.length > 0) {
            preferredFuelStops.forEach(function (preferredFuelStop) {
                features.push({
                    type: 'Feature',
                    properties: {
                        name: preferredFuelStop.name,
                        street: preferredFuelStop.address.street,
                        city: preferredFuelStop.address.city,
                        state: preferredFuelStop.address.state,
                        zip: preferredFuelStop.address.zip,
                        price: preferredFuelStop.price
                    },
                    geometry: {
                        type: 'Point',
                        coordinates: [preferredFuelStop.coords.lon, preferredFuelStop.coords.lat],
                    },
                });
            });
        }

        if (stops && stops.length > 0) {
            stops.forEach(function (stop) {
                features.push({
                    type: 'Feature',
                    properties: {
                        name: stop.companyName,
                        street: stop.address1,
                        city: stop.city,
                        state: stop.state,
                        zip: stop.zip
                    },
                    geometry: {
                        type: 'Point',
                        coordinates: [stop.lon, stop.lat],
                    },
                });
            });
        }

        return {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: features,
            },
        };
    };

    const onOptionChanged = (e) => {
        if (e.name === 'selectedIndex' && parseInt(e.value) >= 0 && parseInt(e.value) !== parseInt(mapStyle)) {
            setMapStyle(e.value);
        }
    };

    return (
        <>
            <div id="map"></div>
            <Tabs dataSource={tabs} selectedIndex={parseInt(mapStyle)} onOptionChanged={onOptionChanged} />
        </>
    );
};
export default Map;
