import React, { useEffect } from 'react';
import { useAuthentication } from 'services';

const Logout = () => {

    const { logout } = useAuthentication()

    useEffect(() => {
        logout(`${process.env.REACT_POST_LOGOUT_URL}/`);
    }, []);

    return <div className={'field-title-center'}>Logging out...</div>
};

export default Logout;
