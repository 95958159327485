import React, { useState, useEffect } from 'react';
import { DateBox } from 'devextreme-react/ui/date-box'
import TextBox from 'devextreme-react/ui/text-box';
import Button from 'devextreme-react/ui/button';

const LoadNumberFilter = ({ onLoadChanged }) => {
    const [loadNumber, setLoadNumber] = useState('');
    const [beginDate, setBeginDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [lastLoadNumber, setLastLoadNumber] = useState(null);
    const [lastBeginDate, setLastBeginDate] = useState(null);
    const [lastEndDate, setLastEndDate] = useState(null);

    const loadNumberChanged = (e) => {
        setLoadNumber(e.value);
        setBeginDate(null);
        setEndDate(null);
    };

    const beginDateChanged = (e) => {
        setBeginDate(e.value);
        setLoadNumber(null);
    };

    const endDateChanged = (e) => {
        setEndDate(e.value);
        setLoadNumber(null);
    };

    function loadNumberKeyPressed(e) {
        setBeginDate(null);
        setEndDate(null);
    }

    function beginDateKeyPressed(e) {
        setLoadNumber(null);
    }

    function endDateKeyPressed(e) {
        setLoadNumber(null);
    }

    function fireChangeEvent() {
        if ((lastLoadNumber != loadNumber) || (lastBeginDate != beginDate) || (lastEndDate != endDate)) {
            setLastLoadNumber(loadNumber);
            setLastBeginDate(beginDate);
            setLastEndDate(endDate);
            if (loadNumber || (beginDate && endDate && new Date(beginDate) < new Date(endDate))) {
                onLoadChanged({ 'loadNumber': loadNumber, 'beginDate': beginDate, 'endDate': endDate })
            }
        }
    }

  return (
    <div className="filter-container">
      <table className="filter-table">
        <tbody>
          <tr>
            <td className="filter-by-driver-three-columns">
                <TextBox id="LoadNumber" value={loadNumber} onKeyPress={loadNumberKeyPressed} placeholder={'Enter Load Number'} onValueChanged={loadNumberChanged} onEnterKey={() => fireChangeEvent()} />
            </td> <td><b>OR</b></td>
            <td className="filter-by-load-number-three-columns">
                <DateBox id="BeginDate" showClearButton={true} value={beginDate} onKeyPress={beginDateKeyPressed} placeholder={'Enter Begin Date'} onValueChanged={beginDateChanged} onEnterKey={() => fireChangeEvent()} />
            </td>
            <td className="filter-by-load-number-three-columns">
                <DateBox id="EndDate" showClearButton={true} value={endDate} onKeyPress={endDateKeyPressed} placeholder={'Enter End Date'} onValueChanged={endDateChanged} onEnterKey={() => fireChangeEvent()} />
            </td>
                 <td className="filter-by-load-number-button">
                <Button type="default" width={35} height={35} icon="icon ion-android-search" text="" onClick={() => fireChangeEvent()} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default LoadNumberFilter
