import React, { FC, useState, useEffect, Fragment } from 'react'
import { StopDetail } from 'components'
import 'devextreme/data/odata/store'
import { useParamService, useDispatchService } from 'services';
import { StopDetailsModel } from 'types'
import { faSpinner } from '@fortawesome/pro-solid-svg-icons' //more icons at: https://fontawesome.com/icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const StopDetails: FC = () => {

    const { getIntValue, getStringValue } = useParamService()
    const { getStopDetails } = useDispatchService();
    const [stopDetailsData, setStopDetailsData] = useState<StopDetailsModel>({} as StopDetailsModel);

    // Passed to the page from the Stops component.
    const [stopId] = useState<number>(getIntValue('stopId'))
    const [loadNumber] = useState<string>(getStringValue('loadNumber') as string)

    useEffect(() => {

        async function getDataSource() {
            let results: any = [];

            if (stopId && stopId > 0) {
                results = await getStopDetails(loadNumber, stopId);
                // This commented out line below will print all the values in the object.
                // console.log(JSON.stringify(results));
            }
            if (results) {
                setStopDetailsData(results);
            }
        }
        getDataSource();
    }, [stopId])

    return (
        <Fragment>
            {
                stopDetailsData.stops ?
                    <StopDetail stopDetails={stopDetailsData} loadNumber={loadNumber}>
                    </StopDetail >
                    :
                    <div className="center-icon center full-height red-component fas fa-spinner fa-spin"><FontAwesomeIcon icon={faSpinner} size={'3x'} /></div>
            }
        </Fragment>
    )
}

export default StopDetails