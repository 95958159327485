import React from 'react';

const CompanyNotes = ({ stop }) => {
  return (
    stop &&
    (stop.directions || stop.generalNotes || stop.safetyNotes) && (
      <tr>
        <td className="company-notes-container" colSpan="3">
          <div className="company-notes">
            <div className="company-notes-header">*** COMPANY NOTES ***</div>
            <div className="company-notes-message">[ directions may vary slightly with turn-by-turn above ]</div>
            <table className="company-notes-table">
              <tbody>
                {stop.lastMileDirections && (
                  <React.Fragment>
                    <tr>
                      <th className="company-notes-table-header">Directions:</th>
                    </tr>
                    <tr>
                      <td className="company-notes-table-text"><pre>{stop.lastMileDirections}</pre></td>
                    </tr>
                  </React.Fragment>
                )}
                {stop.generalNotes && (
                  <React.Fragment>
                    <tr>
                      <th className="company-notes-table-header">General Notes:</th>
                    </tr>
                    <tr>
                      <td className="company-notes-table-text"><pre>{stop.generalNotes}</pre></td>
                    </tr>
                  </React.Fragment>
                )}
                {stop.safetyNotes && (
                  <React.Fragment>
                    <tr>
                      <th className="company-notes-table-header">Safety Notes:</th>
                    </tr>
                    <tr>
                      <td className="company-notes-table-text"><pre>{stop.safetyNotes}</pre></td>
                    </tr>
                  </React.Fragment>
                )}
              </tbody>
            </table>
          </div>
        </td>
      </tr>
    )
  );
};
export default CompanyNotes;
