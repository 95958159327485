import React, { useEffect, useState } from "react";
import "./ExpenseApproval.scss";
import PopupComponent from "components/PopupComponent/PopupComponent";
import ExpenseOption from "components/ExpenseOption/ExpenseOption";
import ExpenseDataGrid from "components/ExpenseDataGrid/ExpenseDataGrid";
import LoadPanelComponent from "components/LoadPanelComponent/LoadPanelComponent";
import ButtonComponent from "components/ButtonComponent/ButtonComponent";
import SubmitExpenseForm from "components/Forms/SubmitExpenseForm";

import notify from "devextreme/ui/notify";
import {
  applyFilterTypes,
  calculateFilterExpression,
  orderHeaderFilter,
} from "../../helpers/ApplyFilters";
import { useHistory } from "react-router-dom";
import useExpenseApprovalService from "services/use-expense-approval";
import { OverLimitExpenseResponse, ValidateExpenseResponse } from "types";
import ValidationDataGrid from "components/ValidationDataGrid/ValidationDataGrid";

const ExpenseApproval = () => {
  const saleAmountEditorOptions = { format: "currency", showClearButton: true };
  const today = new Date();
  const filteredDay = new Date(today.setDate(today.getDate() - 30));
  let dataGridValue: any = null;
  const history = useHistory();
  const {
    getImage,
    deleteImage,
    validateExpenses,
    submitExpense,
    getUserProfileId,
    deleteExpense,
    GetDriverProfileByUserId,
    filteredExpenses,
    getExpenseTypes,
    addImage,
    createExpense,
  } = useExpenseApprovalService();
  const [duplicateExpenseData, setDuplicateExpenseData] = useState<
    ValidateExpenseResponse[]
  >([]);
  const [overlimitExpenseData, setOverlimitExpenseData] = useState<
    OverLimitExpenseResponse[]
  >([]);
  const [receiptPopupVisible, setReceiptPopupVisible] = useState<boolean>(
    false
  );
  const [showValidationPopup, setShowValidationPopup] = useState<boolean>(
    false
  );
  const [showSubmitExpensePopup, setShowSubmitExpensePopup] = useState<boolean>(
    false
  );

  const [showValidate, setShowValidate] = useState<boolean>(false);
  const [filterExpense, setFilterExpense] = useState<any>([]);
  const [loadPanelVisible, setLoadPanelVisible] = useState<boolean>(false);
  const [imgState, setImgState] = useState<any>({
    selectedFile: [],
    imageProfileId: null,
  });

  const [state, setState] = useState<any>({
    showFilterRow: false,
    showHeaderFilter: false,
    currentFilter: applyFilterTypes[0].key,
    submitExpenseForm: {},
    validateFormData: [],
    showIndicator: true,
    shading: true,
    showPane: true,
    hideOnOutsideClick: false,
    canShowReceipt: false,
    canShowSubmitExpense: false,
    baseImageValue: "",
    masterExpenseTypesData: [],
    expenseTypeOptions: {},
  });

  const handleImageUpload = (e: any) => {
    if (
      e.target &&
      e.target.files &&
      e.target.files[0] &&
      e.target.files[0] != null &&
      e.target.files[0].name.length > 0
    ) {
      e.preventDefault();
      handleUploadImage(e.target);
    }
  };

  const handleUploadImage = async (data: any) => {
    const imageFormData: any = new FormData();
    const userId: any = await getUserProfileId();
    await imageFormData.append("userId", userId.id);
    await imageFormData.append("imageFile", data.files[0]);
    const response: any = await addImage(imageFormData);
    if (response && response.isSuccess === true) {
      setImgState({
        imageProfileId: response.id,
        selectedFile: data.files,
      });
      notify(
        {
          message: "Image Uploaded successfully!!",
          position: {
            my: "center top",
            at: "center top",
          },
        },
        "success",
        3000
      );
    }
  };

  const handleClearImage = async () => {
    const userId: any = await getUserProfileId();
    const response: any = await deleteImage({
      id: imgState.imageProfileId,
      userId: userId.id,
    });
    if (response && response.isDeleted === true) {
      setImgState({
        selectedFile: [],
        imageProfileId: null,
      });
      notify(
        {
          message: "Image Deleted successfully!!",
          position: {
            my: "center top",
            at: "center top",
          },
        },
        "success",
        3000
      );
    }
  };

  const getDriverExpenseTypes = async () => {
    const response: any = await getExpenseTypes();
    setState({
      ...state,
      masterExpenseTypesData: response,
      expenseTypeOptions: {
        items: response.map((expense: any) => expense.description),
        value: state.submitExpenseForm.description
          ? state.submitExpenseForm.description
          : response.map((expense: any) => expense.description)[0],
      },
    });
  };

  const getFilteredExpenses = async () => {
    setLoadPanelVisible(true);
    const userId: any = await getUserProfileId();

    const driverProfile: any = await GetDriverProfileByUserId(userId?.id);
    const filterPayload = {
      driverId: driverProfile.employeeId,
      regions: [{ description: driverProfile.regionCode }],
    };

    const response: any = await filteredExpenses(filterPayload);
    setLoadPanelVisible(false);
    setFilterExpense(
      response
        .map((item: any) => {
          if (item && (item.expenseStatus.description === "Denied" || item.expenseStatus.description === "Created")) {
            return Object.assign({ canEdit: true }, item);
          } else {
            return Object.assign({ canEdit: false }, item);
          }
        })
        .map((item: any) => {
          if (item && item.comments && item.comments.length > 0) {
            return Object.assign(
              {
                comment: item.comments[item.comments.length - 1].comment,
              },
              item
            );
          } else {
            return Object.assign(
              {
                comment: "",
              },
              item
            );
          }
        })
        .filter((item: any) => new Date(item.submitDate) >= filteredDay)
    );
  };
  useEffect(() => {
    getFilteredExpenses();
    getDriverExpenseTypes();
  }, []);

  const createNewExpense = async (tempObj: any, userId: any) => {
    const response: any = await createExpense(tempObj, userId.id);
    if (response && response.expenseStatus.description === "Created") {
      setImgState({ imageProfileId: null, selectedFile: [] });
      setLoadPanelVisible(true);
      const responseTwo: any = await submitExpense(
        [{ id: response.id }],
        userId.id
      );
      if (responseTwo.length > 0) {
        setLoadPanelVisible(false);
        getFilteredExpenses();
        setShowValidate(false);
        setShowValidationPopup(false);
        setShowSubmitExpensePopup(false);
        notify(
          {
            message: "Expense submitted successfully!!",
            position: {
              my: "center top",
              at: "center top",
            },
          },
          "success",
          3000
        );
      }
    }
  };

  const getSubmitExpenseData = async () => {
    setShowSubmitExpensePopup(false);
    setShowValidate(false);
    const userId: any = await getUserProfileId();
    const deleteOldExpense: any = await deleteExpense(
      { id: state.submitExpenseForm.id },
      userId.id
    );
    if (deleteOldExpense && deleteOldExpense.isDeleted === true) {
      const driverProfile: any = await GetDriverProfileByUserId(userId?.id);
      const driverEmployeeId = driverProfile.employeeId;
      const driverRegionCode = driverProfile.regionCode;
      const expenseTypeId = state.masterExpenseTypesData.find(
        (elem: any) => elem.description === state.submitExpenseForm.description
      );

      let tempObj: any = {
        id: 0,
        driverEmployeeId: driverEmployeeId,
        driverRegionCode: driverRegionCode,
        expenseTypeId: expenseTypeId.id,
        requestedAmount: state.submitExpenseForm.requestedAmount,
        receiptDate: state.submitExpenseForm.receiptDate,
        imageProfileId: imgState.imageProfileId,
        comments:
          state.submitExpenseForm.comment.length > 0
            ? [
                {
                  id: 0,
                  expenseTypeId: expenseTypeId.id,
                  comment: state.submitExpenseForm.comment,
                },
              ]
            : [],
      };
      setLoadPanelVisible(true);
      createNewExpense(tempObj, userId);
    }
  };

  const handleSubmitData = async () => {
    const {
      comment,
      description,
      receiptDate,
      requestedAmount,
    }: any = state.submitExpenseForm;
    if (
      comment === "" ||
      description == null ||
      receiptDate == null ||
      requestedAmount == null
    ) {
      return;
    }
    setShowSubmitExpensePopup(false);
    const response: any = await validateExpenses(state.submitExpenseForm);
    if (
      response?.duplicateExpenses?.length === 0 &&
      response?.overLimitExpenses?.length === 0
    ) {
      getSubmitExpenseData();
    } else {
      if (response?.duplicateExpenses?.length > 0) {
        setDuplicateExpenseData(response?.duplicateExpenses);
      } else if (response?.overLimitExpenses?.length > 0) {
        setOverlimitExpenseData(response?.overLimitExpenses);
      }
      setShowValidate(true);
      setShowValidationPopup(true);
    }
  };

  function handleDeleteExpense(e: any) {
    const isDeleteExpense = new Promise(async (resolve, reject) => {
      const dataValue: any = await getUserProfileId();
      if (dataValue && dataValue.id) {
        const userId = dataValue?.id;
        const id = e.key;
        setLoadPanelVisible(true);
        const response: any = await deleteExpense({ id }, userId);
        if (response && response.isDeleted === true) {
          resolve(false);
          setLoadPanelVisible(false);

          notify(
            {
              message: "Expense Deleted successfully!!",
              position: {
                my: "center top",
                at: "center top",
              },
            },
            "warning",
            3000
          );
        } else {
          reject("failure");
        }
      }
    });
    e.cancel = isDeleteExpense;
  }

  const closeValidate = () => {
    setShowValidate(false);
  };

  const submitButtonOptions = {
    text: "YES",
    onClick: handleSubmitData,
    type: "default",
    stylingMode: "default",
    disabled: imgState.imageProfileId == null,
    elementAttr: { id: "resubmit-btn" },
  };

  const validatePopupOptions = {
    text: "Proceed",
    onClick: getSubmitExpenseData,
    type: "default",
    stylingMode: "default",
  };

  const closeValidateButtonOptions = {
    text: "Close",
    onClick: closeValidate,
  };

  const onShowFilterRowChanged = (e: any) => {
    setState({
      ...state,
      showFilterRow: e.value,
    });
    clearFilter();
  };

  const onShowHeaderFilterChanged = (e: any) => {
    setState({
      ...state,
      showHeaderFilter: e.value,
    });
    clearFilter();
  };

  const onCurrentFilterChanged = (e: any) => {
    setState({
      ...state,
      currentFilter: e.value,
    });
  };

  const clearFilter = () => {
    if (dataGridValue) dataGridValue.instance.clearFilter();
  };

  const canDisplayIcons = (e: any) => {
    return e.row.data.canEdit;
  };

  const handleEditDenyModal = async (e: any) => {
    const {
      expenseType: { description },
      requestedAmount,
      comment,
      receiptDate,
      id,
      imageProfileId,
    } = e.row.data;

    const tempObj: any = {
      id,
      description,
      requestedAmount,
      comment,
      receiptDate,
      imageProfileId,
    };

    setShowSubmitExpensePopup(true);
    setState({
      ...state,
      submitExpenseForm: tempObj,
    });
  };

  const getImageData: any = async (imageId: any) => await getImage(imageId);

  const handleImagePreview = async (imageProfileId: string) => {
    const response: string = await getImageData(imageProfileId);
    if (response && response.length > 0) {
      setState({
        ...state,
        baseImageValue: response,
        canShowReceipt: true,
        canShowSubmitExpense: false,
      });
      setReceiptPopupVisible(true);
    }
  };

  const handleCreateSubmission = () => {
    history.push("/createexpense");
  };

  const hideLoadPanel = () => {
    setLoadPanelVisible(false);
  };

  const hideReceiptInfo = () => {
    setReceiptPopupVisible(false);
  };

  const closeValidationPopup = () => {
    setShowValidationPopup(false);
  };

  const closeSubmitExpensePopup = () => {
    setShowSubmitExpensePopup(false);
  };

  const closeReceiptImageButtonOptions = {
    text: "Close",
    onClick: hideReceiptInfo,
  };

  const closeSubmitExpenseButtonOptions = {
    text: "Close",
    onClick: closeSubmitExpensePopup,
  };

  return (
    <div>
      <div id="maincontent">
        <div
          className="header-row"
          style={{
            display: "block",
            alignItems: "center",
          }}
        >
          <ExpenseOption
            currentFilter={state.currentFilter}
            onCurrentFilterChanged={onCurrentFilterChanged}
            showFilterRow={state.showFilterRow}
            onShowFilterRowChanged={onShowFilterRowChanged}
            onShowHeaderFilterChanged={onShowHeaderFilterChanged}
            showHeaderFilter={state.showHeaderFilter}
          />
          <ButtonComponent
            handleClick={handleCreateSubmission}
            type={"default"}
            stylingMode={"contained"}
            text={"CREATE SUBMISSION"}
            width={300}
            id={"create-submission-btn"}
          />
        </div>
        <PopupComponent
          submitButtonOptions={submitButtonOptions}
          popupVisible={showSubmitExpensePopup}
          hideInfo={closeSubmitExpensePopup}
          closeButtonOptions={closeSubmitExpenseButtonOptions}
          title="SUBMIT EXPENSE"
        >
          <SubmitExpenseForm
            positionOptions={state.expenseTypeOptions}
            submitExpenseForm={state.submitExpenseForm}
            selectedFile={imgState.selectedFile}
            handleClearImage={handleClearImage}
            handleImageUpload={handleImageUpload}
          />
        </PopupComponent>

        {showValidate && (
          <PopupComponent
            submitButtonOptions={validatePopupOptions}
            popupVisible={showValidationPopup}
            hideInfo={closeValidationPopup}
            closeButtonOptions={closeValidateButtonOptions}
            title="Validation Result"
          >
            <ValidationDataGrid
              duplicateExpenseData={duplicateExpenseData}
              overlimitExpenseData={overlimitExpenseData}
            />
          </PopupComponent>
        )}
        <ExpenseDataGrid
          dataGridValue={dataGridValue}
          filterExpense={filterExpense}
          onRowRemoving={handleDeleteExpense}
          canDisplayIcons={canDisplayIcons}
          handleEditDenyModal={handleEditDenyModal}
          showFilterRow={state.showFilterRow}
          currentFilter={state.currentFilter}
          showHeaderFilter={state.showHeaderFilter}
          calculateFilterExpression={calculateFilterExpression}
          orderHeaderFilter={orderHeaderFilter}
          saleAmountEditorOptions={saleAmountEditorOptions}
          handleImagePreview={handleImagePreview}
          popupVisible={receiptPopupVisible}
          hideReceiptInfo={hideReceiptInfo}
          closeButtonOptions={closeReceiptImageButtonOptions}
          canShowReceipt={state.canShowReceipt}
          canShowSubmitExpense={state.canShowSubmitExpense}
          baseImageValue={state.baseImageValue}
          handleDeleteExpense={handleDeleteExpense}
        />
        <LoadPanelComponent
          hideLoadPanel={hideLoadPanel}
          loadPanelVisible={loadPanelVisible}
          showIndicator={state.showIndicator}
          shading={state.shading}
          showPane={state.showPane}
          positionId={".dx-datagrid-rowsview"}
        />
      </div>
    </div>
  );
};

export default ExpenseApproval;
