import React from 'react';

const ReportLines = ({ lines }) => {
  let keyValue = 0;

  return (
    lines.length > 0 && (
      <React.Fragment>
        <tr className="leg-table-column-header">
          <th></th>
          <th className="leg-table-column-header">Time</th>
          <th className="leg-table-column-header">Distance</th>
        </tr>
        {lines.map((line) => {
          keyValue++;

          return (
            keyValue > 1 && 
            line.begin !== null &&
            line.begin.lat !== '0' &&
            line.begin.lon !== '0' && (
              <tr key={keyValue}>
                {line.dist == null ? (
                  keyValue === 2 ? (
                    <td className="direction-first" colSpan="3">
                      {line.direction}
                    </td>
                  ) : (
                    <td className="direction" colSpan="3">
                      {line.direction}
                    </td>
                  )
                ) : (
                  <React.Fragment>
                    <td className="sub-direction-description">{line.direction}</td>
                    <td className="sub-direction-time">{line.time}</td>
                    <td className="sub-direction-distance">{parseFloat(line.dist).toFixed(1)} mi</td>
                  </React.Fragment>
                )}
              </tr>
            )
          );
        })}
      </React.Fragment>
    )
  );
};
export default ReportLines;
