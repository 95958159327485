import React from 'react'
import ContextMenu from 'devextreme-react/ui/context-menu'
import List from 'devextreme-react/ui/list'
import './user-panel.scss'

class UserPanel extends React.Component {
    render() {
        const { menuMode, menuItems, user } = this.props;
        return (
            <div className={'user-panel'}>
                <div className={'user-info'}>
                    <div className={'user-name'}>{user ? 'Welcome, ' + user.name : 'Loading...'}</div>
                </div>
                {menuMode === 'context' && (
                    <ContextMenu
                        items={menuItems}
                        target={'.user-button'}
                        showEvent={'dxclick'}
                        position={{ my: 'top center', at: 'bottom center' }}
                        cssClass={'user-menu'}
                    />
                )}
                {menuMode === 'list' && (
                    <List className={'dx-toolbar-menu-action'} items={menuItems} />
                )}
            </div>
        );
    }
}
export default UserPanel;