import Drawer from 'devextreme-react/drawer';
import ScrollView from 'devextreme-react/scroll-view';
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Header, SideNavigationMenu, Footer } from '../../components';
import './side-nav-outer-toolbar.scss';
import { useScreenSize } from '../../utils/media-query';
import { Template } from 'devextreme-react/core/template';
import { useMenuPatch } from '../../utils/patches';
import { useAuthentication } from 'services';

export default function ({ title, children, parentPath }) {
    const scrollViewRef = useRef();
    const history = useHistory();
    const { isXSmall, isLarge } = useScreenSize();
    const [patchCssClass, onMenuReady] = useMenuPatch();
    const [menuStatus, setMenuStatus] = useState(isLarge ? MenuStatus.Opened : MenuStatus.Closed);
    const { getUser } = useAuthentication()
    const [user, setUser] = useState('');

    const toggleMenu = useCallback(({ event }) => {
        setMenuStatus((prevMenuStatus) => (prevMenuStatus === MenuStatus.Closed ? MenuStatus.Opened : MenuStatus.Closed));
        event.stopPropagation();
    }, []);

    useEffect(() => {
        const getLoggedInUser = async () => {
            var user = await getUser();
            setUser(user);
        };
        getLoggedInUser();
    }, []);

    const userMenuItems = [
        {
            text: 'Logout',
            icon: 'runner',
            onClick: () => window.location.href = "logout"
        }
    ];

    const temporaryOpenMenu = useCallback(() => {
        setMenuStatus((prevMenuStatus) => (prevMenuStatus === MenuStatus.Closed ? MenuStatus.TemporaryOpened : prevMenuStatus));
    }, []);

    const onOutsideClick = useCallback(() => {
        setMenuStatus((prevMenuStatus) => (prevMenuStatus !== MenuStatus.Closed && !isLarge ? MenuStatus.Closed : prevMenuStatus));
    }, [isLarge]);

    const onNavigationChanged = useCallback(
        ({ itemData: { path }, event, node }) => {
            if (menuStatus === MenuStatus.Closed || !path || node.selected) {
                event.preventDefault();
                return;
            }

            history.push(path);
            scrollViewRef.current && scrollViewRef.current.instance.scrollTo(0);

            if (!isLarge || menuStatus === MenuStatus.TemporaryOpened) {
                setMenuStatus(MenuStatus.Closed);
                event.stopPropagation();
            }
        },
        [history, menuStatus, isLarge]
    );

    return (
        <div className={'side-nav-outer-toolbar'}>
            <Header
                className={'layout-header'}
                menuToggleEnabled
                toggleMenu={toggleMenu}
                title={title}
                user={user}
                userMenuItems={userMenuItems}
                parentPath={parentPath}
            />

            <Drawer
                className={['drawer', patchCssClass].join(' ')}
                position={'before'}
                closeOnOutsideClick={onOutsideClick}
                openedStateMode={isLarge ? 'shrink' : 'overlap'}
                revealMode={isXSmall ? 'slide' : 'expand'}
                minSize={isXSmall ? 0 : 60}
                maxSize={250}
                shading={isLarge ? false : true}
                opened={menuStatus === MenuStatus.Closed ? false : true}
                template={'menu'}
            >
                <div className={'container'}>
                    <ScrollView ref={scrollViewRef} className={'layout-body with-footer'}>
                        <div className={'content'}>
                            {React.Children.map(children, (item) => {
                                return item.type !== Footer && item;
                            })}
                        </div>
                        <div className={'content-block'}>
                            {React.Children.map(children, (item) => {
                                return item.type === Footer && item;
                            })}
                        </div>
                    </ScrollView>
                </div>
                <Template name={'menu'}>
                    <SideNavigationMenu compactMode={menuStatus === MenuStatus.Closed} selectedItemChanged={onNavigationChanged} openMenu={temporaryOpenMenu} onMenuReady={onMenuReady}></SideNavigationMenu>
                </Template>
            </Drawer>
        </div>
    );
}

const MenuStatus = {
    Closed: 1,
    Opened: 2,
    TemporaryOpened: 3,
};
