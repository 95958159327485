import React, { FC } from "react";
import DataGrid, { Column } from "devextreme-react/data-grid";
import PopupComponent from "components/PopupComponent/PopupComponent";
import "./CreatedExpenseGrid.scss";
import { Button } from "devextreme-react";
import { useScreenSize } from "../../utils/media-query";

interface Props {
  expenseTable?: any;
  handleDeleteExpense?: (e: any) => void;
  popupVisible?: boolean;
  hideInfo?: () => void;
  openDeleteConfirm?: (e: any) => void;
  showValidate?: boolean;
}

const CreatedExpenseGrid: FC<Props> = ({
  expenseTable,
  handleDeleteExpense,
  popupVisible,
  hideInfo,
  openDeleteConfirm,
  showValidate,
}) => {
  const screenSize = useScreenSize();

  const submitButtonOptions = {
    text: "YES",
    onClick: handleDeleteExpense,
    type: "default",
    stylingMode: "default",
    disabled: false,
  };

  const closeButtonOptions = {
    text: "Close",
    onClick: hideInfo,
  };

  const showActionButton = (e: any) => {
    return (
      <div>
        <Button
          className="action-delete-btn"
          onClick={() => openDeleteConfirm && openDeleteConfirm(e)}
        >
          DELETE
        </Button>
      </div>
    );
  };

  return (
    <div>
      <br />
      <p className="driver-info">Created New Expense List</p>
      <DataGrid
        id="gridContainer"
        dataSource={expenseTable}
        keyExpr="id"
        showBorders={true}
        rowAlternationEnabled={true}
        hoverStateEnabled={false}
        columnHidingEnabled={true}
      >
        <Column
          dataField="expenseType.description"
          caption="Expense Type"
          width={screenSize.isXSmall && 120}
        />
        <Column
          dataField="requestedAmount"
          caption="Requested Amount"
          dataType="number"
          format={{ type: "currency", precision: 2 }}
          width={screenSize.isXSmall && 100}
        />
        <Column
          dataField="receiptDate"
          caption="Receipt Date"
          dataType="datetime"
          format="M/d/yyyy"
        />
        <Column
          dataField="expenseStatus.description"
          caption="Expense Status"
        />
        <Column dataField="comment" caption="Comment" />
        <Column cellRender={showActionButton} />
      </DataGrid>
      <PopupComponent
        popupVisible={!showValidate && popupVisible}
        submitButtonOptions={submitButtonOptions}
        closeButtonOptions={closeButtonOptions}
      >
        <span>Do you want to delete this expense?</span>
      </PopupComponent>
    </div>
  );
};

export default CreatedExpenseGrid;
