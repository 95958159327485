import React from 'react';
import { PreferredFuelStop } from '.';
import ScrollView from 'devextreme-react/scroll-view';
import './preferred-fuel-stops.scss'

const PreferredFuelStops = ({ stops }) => {
  let keyValue = 0;

  return (
    <div className="card">
        <ScrollView id="preferredFuelLocationsScrollview"
          scrollByContent={true}
          bounceEnabled={false}
          showScrollbar="always"
          scrollByThumb={true}>
        <div id="preferredFuelLocations" className="container-content">
          <table className="fuel-stops-table">
            {stops && stops.length > 0 ? (
              <React.Fragment>
                <thead>
                  <tr>
                    <th className="fuel-stop-column-header"></th>
                    <th className='fuel-stop-column-header'>Price</th>
                    <th className="fuel-stop-column-header">Distance</th>
                    <th className="fuel-stop-column-header">Off Route</th>
                  </tr>
                </thead>
                <tbody>
                  {stops.map((preferredFuelStop) => {
                    keyValue++;

                    return <PreferredFuelStop key={keyValue} keyValue={keyValue} stop={preferredFuelStop} />;
                  })}
                </tbody>
              </React.Fragment>
            ) : (
              <tbody>
                <tr>
                  <td className="fuel-stop-no-data">no fuel stops found</td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      </ScrollView>
    </div>
  );
};
export default PreferredFuelStops;
