import React, { FC } from 'react';
import 'devextreme/data/odata/store';
import { StopHeaderBox, ConfirmBox, Totals } from 'components/load-confirmation'

type Props = {
    confirmationNumber: string,
    orderNumber: string,
    temperature: string,
    weight: string,
    cube: string,
    pieceCount: string,
    items: string
}

const OrderTotals: FC<Props> = ({ confirmationNumber, orderNumber, temperature, weight, cube, pieceCount, items }) => {

    const headerValue: string = 'Order: ' + orderNumber;

    return (
        <div className={`main-container-item-container`} >
            <StopHeaderBox headerTitle={headerValue} temperature={temperature} />
            <div className={"left-margins right-margins"} >
                <ConfirmBox confirmationNumber={confirmationNumber} />
                <div className={"left-margins"} >
                    <Totals weight={weight} cube={cube} pieceCount={pieceCount} items={items} />
                </div>
            </div>
        </div>
    );

};

export default OrderTotals