import React, { FC } from "react";
import CheckBox from "devextreme-react/check-box";

interface Props {
  text?: string;
  value?: boolean;
  onValueChanged?: (e: any) => void;
}

const CheckBoxComponent: FC<Props> = ({ text, value, onValueChanged }) => {
  return <CheckBox text={text} value={value} onValueChanged={onValueChanged} />;
};

export default CheckBoxComponent;
