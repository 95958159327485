import React, { useState } from 'react'
import './reconcile.scss'
import { ReconcileDetail, LoadNumberFilter } from 'components'
import { SummaryHeader } from 'components'
import 'devextreme/data/odata/store'
import { ReconcileDetailEvent } from 'types'
import { faMoneyCheck } from '@fortawesome/pro-duotone-svg-icons' //more icons at: https://fontawesome.com/icons

let dateformat = require('dateformat')

export default () => {

    const [selectedLoadNumber, setSelectedLoadNumber] = useState<string>('');
    const [selectedBeginDate, setSelectedBeginDate] = useState<string>('');
    const [selectedEndDate, setSelectedEndDate] = useState<string>('');
    const [showResults, setShowResults] = useState(false);

    const onValueChanged = (e: ReconcileDetailEvent) => {
        if (e != null) {

            var beginDate: string = e.beginDate ? dateformat(e.beginDate, 'paddedShortDate') : ''
            var endDate: string = e.endDate ? dateformat(e.endDate, 'paddedShortDate') : ''

            setSelectedLoadNumber(e.loadNumber)
            setSelectedBeginDate(beginDate)
            setSelectedEndDate(endDate)
            setShowResults(true)
        }
    }

    return (
        <React.Fragment>
            <SummaryHeader title='Reconcile Accuracy' icon={faMoneyCheck} color='#03a9f4' />
            <LoadNumberFilter onLoadChanged={onValueChanged} />
            <br />
            { showResults &&
                    <ReconcileDetail selectedBeginDate={selectedBeginDate} selectedEndDate={selectedEndDate} selectedLoadNumber={selectedLoadNumber} />
            }
        </React.Fragment>
    )
}