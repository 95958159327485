import React, { FC } from "react";
import "./ImagePreview.scss";

interface Props {
  baseImageValue?: string;
}

const ImagePreview: FC<Props> = ({ baseImageValue }) => {
  return (
    <div>
      <img
        id="imageStyle"
        src={`data:image/jpeg;base64, ${baseImageValue}`}
        alt="Dot"
      />
    </div>
  );
};

export default ImagePreview;
