import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './dx-styles.scss';
import './App.scss';
import { NavigationProvider } from './contexts/navigation';
import { SecureRouter } from './components/okta';


export default function () {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <NavigationProvider>
        <SecureRouter />
      </NavigationProvider>
    </Router>
  );
}
