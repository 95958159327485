import React from 'react';

const LegSeparator = () => {
  return (
    <tr>
      <td colSpan="3">
        <hr className="leg-separator" />
      </td>
    </tr>
  );
};
export default LegSeparator;
