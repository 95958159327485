import React from "react";
import Box, { Item } from "devextreme-react/box";
import "./due-out.scss";
import { faTruck } from '@fortawesome/pro-solid-svg-icons' //more icons at: https://fontawesome.com/icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

let dateFormat = require('dateformat')

export default ({ message, timeZone }) => {
    return (
        <Box width={"100%"} className="rect due-out-color msg-borders ">
            <Item ratio={1} >
                <div className={"due-out-truck-icon-div"}>
                    <FontAwesomeIcon icon={faTruck} className={"due-out-truck-icon"} size={"1x"} />
                </div>
            </Item>
            <Item ratio={5.5}>
                <div className={"due-out-div "}>Due Out: {dateFormat(message, 'mm/dd - HH:MM')} {timeZone}</div>
            </Item>
        </Box>
    );
};