import React, { Fragment, useState, useEffect } from 'react'
import { useAuthentication, useDispatchService } from 'services'
import { LoadHeader, HeaderBox, MsgBox, Stops, Totals, Title, Confirm, DueOut } from 'components/load-confirmation'
import './load-confirmation.scss'
import { faSpinner, faAlarmExclamation, faWrench } from '@fortawesome/pro-solid-svg-icons' //more icons at: https://fontawesome.com/icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useInterval from 'hooks/use-interval'
import { useHistory } from 'react-router'
import { Button } from 'devextreme-react/button';
import { useScreenSize } from 'utils'

export default () => {

    const history = useHistory();
    const screen = useScreenSize()

    const { getUserClaim } = useAuthentication();
    const [confirmations, setConfirmations] = useState<any>(null);
    const [previousConfirmations, setPreviousConfirmations] = useState<any>(null);
    const [errorMessage, setErrorMessage] = useState<string>('');
    let { getDriverConfirmations, confirmLoad } = useDispatchService();

    const getData = async () => {
        try {
            let driverId = await getUserClaim('driverId');
            if (driverId) {
                let stringValue = driverId.toString();
                let data = await getDriverConfirmations(stringValue);
                setConfirmations(data);
                refreshChanges(data);
                setPreviousConfirmations(data);
                setErrorMessage('');
            }
        }
        catch (error:any) {
            if (error.response != null && error.response.data != null && error.response.data.message != null) {
                setErrorMessage("Error: " + error.response.data.message);
            }
            else {
                setErrorMessage(error.message);
            }
        }
    }

    function refreshChanges(confirmations: any): void {
        // Refresh a graphic icon indicating a load changed.
        if (previousConfirmations != null && confirmations != null) {
            confirmations.map((confirmation: any, index: number) => {
                if (index < previousConfirmations.length) {
                    if ((previousConfirmations[index].changed == true)
                        || (previousConfirmations[index].stops.length != confirmation.stops.length)) {
                        confirmation.changed = true;
                    }
                }
            }
            )
            setConfirmations(confirmations);
        }
    }

    function setMoreDetailsVisible(load: string) {
        history.push('/preferredRoute?loadNumber=' + load);
    }

    useInterval(
        () => {
            getData();
        },
        // Refresh the display every 60 seconds (below is the delay in milliseconds, or null to stop the timer).
        60000,
    )

    useEffect(() => {
        getData();
    }, [])

    const onConfirmClick = async (loadNumber: string) => {
        var result = await confirmLoad(loadNumber);
        if (result) {
            // Refresh the view.
            getData();
        }
        else {
            alert("Confirmation failed.");
        }
    }

    return (
        <Fragment>
            {confirmations ? confirmations.length > 0 ?
                <div>
                    {
                        confirmations.map((confirmation: any, index: number) => (
                            <div className="bottomPadded">
                                <div className={`main-container-item-container`} >
                                    <HeaderBox headerTitle={confirmation.confirmationState} changed={confirmation.changed} />
                                    <div className={"inside-card"} >
                                        <LoadHeader load={confirmation.loadNumber} truck={confirmation.truckNumber} driverTwoId={confirmation.driverTwoId} confirmationState={confirmation.confirmationState} />
                                        <DueOut message={confirmation.dueOut} timeZone={confirmation.dueOutTimeZone} />
                                        <MsgBox message={confirmation.comment} />
                                        <Stops loadNumber={confirmation.loadNumber} stops={confirmation.stops} />
                                        <Title title="Totals" />
                                        <Totals weight={confirmation.weight} cube={confirmation.cube} pieceCount={confirmation.pieceCount} items={confirmation.lineItems} mileage={confirmation.mileage} />

                                        <div className="preferred-route-button-div" >
                                            <Button className="preferred-route-button-button" icon="map"
                                                text={screen.isXSmall ? "Route" : "Designated Route"}
                                                onClick={() => setMoreDetailsVisible(confirmation.loadNumber)}
                                            />
                                            <span className="paddedSpan">
                                                {confirmation.confirmationState == "Unconfirmed" && <Confirm loadNumber={confirmation.loadNumber} onConfirmClick={onConfirmClick} />}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
                :
                <div className="center-icon center full-height">
                    <FontAwesomeIcon icon={faWrench} size={'3x'} />
                    <div><br />You have no active loads assigned.
                    </div>
                </div>
                : errorMessage.length > 0 ?
                    <div className="center-icon center full-height red-component">
                        <FontAwesomeIcon icon={faAlarmExclamation} size={'3x'} />
                        <div><br />{errorMessage}
                        </div>
                    </div> :
                    <div className="center-icon center full-height red-component fas fa-spinner fa-spin">
                        <FontAwesomeIcon icon={faSpinner} size={'3x'} />
                    </div>
            }
        </Fragment>
    )
}
