import { useAuthentication } from 'services'
import axios from 'axios'

const useDispatchService = () => {

    const { getAuthHeader } = useAuthentication()
    const serviceUrl = `${process.env.REACT_APP_DISPATCH_SERVICE}`

    const getDriverConfirmations = async (driverId: string): Promise<any> => {
        let response = await axios.get(`${serviceUrl}/Load/NonCompletedLoadsByDriver?driverId=${driverId}`,
            {
                headers: await getAuthHeader()
            })

        return response.data
    }

    const getStopDetails = async (loadNumber: string, stopId: number): Promise<any> => {
        let response = await axios.get(`${serviceUrl}/Load/StopDetails?stopId=${stopId}&loadNumber=${loadNumber}`,
            {
                headers: await getAuthHeader()
            })

        return response.data
    }

    const confirmLoad = async (loadNumber: string): Promise<any> => {
        let response = await axios.post(`${serviceUrl}/Load/ConfirmLoad?loadNumber=${loadNumber}`, null,
            {
                headers: await getAuthHeader()
            })
        return response.data
    }
    return {
        getDriverConfirmations: getDriverConfirmations,
        getStopDetails: getStopDetails,
        confirmLoad: confirmLoad
    }
}

export default useDispatchService