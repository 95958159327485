import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import appInfo from './app-info';
import routes from './app-routes';
import { SideNavOuterToolbar as SideNavBarLayout } from './layouts';
import { useNavigation } from './contexts/navigation';

export default function () {

    const {
        navigationData: { currentPath },
    } = useNavigation();

    let currentRoute = routes.find(route => route.path == currentPath)

    return (
    <SideNavBarLayout parentPath={currentRoute ? currentRoute.parentPath : '/'} title={currentRoute ? currentRoute.title : appInfo.title}>
      <Switch>
        {routes.map(({ path, component }) => (
          <Route exact key={path} path={path} component={component} />
        ))}
        <Redirect to={'/home'} />
      </Switch>
    </SideNavBarLayout>
  );
}
