import React, { FC } from "react";
import CheckBoxComponent from "../CheckBoxComponent/CheckBoxComponent";
import "./ExpenseOption.scss";

interface Props {
  currentFilter?: any;
  onCurrentFilterChanged?: (e: any) => void;
  showFilterRow?: boolean;
  onShowFilterRowChanged?: (e: any) => void;
  onShowHeaderFilterChanged?: (e: any) => void;
  showHeaderFilter?: boolean;
}

const ExpenseOption: FC<Props> = ({
  showFilterRow,
  onShowFilterRowChanged,
  onShowHeaderFilterChanged,
  showHeaderFilter,
}) => {
  return (
    <div className="options" style={{ background: "none" }}>
      <div className="option">
        <span style={{ textDecoration: "underline", whiteSpace: "nowrap" }}>
          Apply Filter
        </span>
      </div>
      <div className="option">
        <CheckBoxComponent
          text={"Row"}
          value={showFilterRow}
          onValueChanged={onShowFilterRowChanged}
        />
      </div>
      <div className="option">
        <CheckBoxComponent
          text={"Header"}
          value={showHeaderFilter}
          onValueChanged={onShowHeaderFilterChanged}
        />
      </div>
    </div>
  );
};

export default ExpenseOption;
