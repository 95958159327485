import React from 'react';

const PreferredFuelStop = ({ keyValue, stop }) => {
  return (
    <React.Fragment>
      <tr>
        {keyValue === 1 ? (
          <td colSpan="3" className="fuel-stop-description-first">
            {stop.name}
          </td>
        ) : (
          <td colSpan="3" className="fuel-stop-description">
            {stop.name}
          </td>
        )}
      </tr>
      <tr>
        <td className="fuel-stop-address">
          {' '}
          {stop.address.street}, {stop.address.city}, {stop.address.state} {stop.address.zip}
        </td>
        <td className='fuel-stop-price'>{stop.price != null && stop.price != undefined ? `$${stop.price.toFixed(2)}` : 'unavailable'}</td>
        <td className="fuel-stop-distance-from-origin">{parseFloat(stop.distanceFromOrigin).toFixed(1)} mi</td>
        <td className="fuel-stop-distance-off-route">{parseFloat(stop.distanceOffRoute).toFixed(1)} mi</td>
      </tr>
    </React.Fragment>
  );
};
export default PreferredFuelStop;
