import React, { FC, Fragment } from 'react';
import "./header-box.scss";

type Props = {
    headerTitle: string,
    changed: boolean
}

const HeaderBox: FC<Props> = ({ headerTitle, changed }) => {

    var classTitleName = 'header-box-heading-item-title-';
    if (headerTitle != null && headerTitle.length > 0) {
        classTitleName = classTitleName + headerTitle.replace(' ', '').toLowerCase();
    }
    else {
        classTitleName = classTitleName + "unconfirmed";
    }

    return (
        <Fragment>
            {
                <div className={'header-box-heading-item-container'} >
                    <div className={classTitleName} >{headerTitle} { changed && " (STOPS CHANGED)"}</div>
                </div>
            }
        </Fragment>
    );
};

export default HeaderBox