import React from 'react';
import { ReportLines, LegAddress, LegTitle, LegSeparator, CompanyNotes } from '.';

const ReportLegs = ({ legs, stops }) => {

    function getLegNumber(stops, thisStop) {
        var count = 1;
        if (stops && Array.isArray(stops)) {
            for (let i = 0; i < stops.length; i++) {
                var stop = stops[i];
                if (stop == thisStop) {
                    return count;
                }
                else {
                    if (stop.companyId != null) {
                        if (i < stops.length - 1) {
                            // Need to check the next stop too and make sure it's not a waypoint.
                            for (let j = i + 1; j < stops.length; j++) {
                                var nextStop = stops[j];
                                if (nextStop.companyId != null) {
                                    nextStop = stop;
                                    count++;
                                    break;
                                }
                            }
                        }
                        else {
                            count++;
                        }
                    }
                }
            }
        }
        return count;
    }

    function getNextStop(stops, thisStop) {
        var started = false;
        var nextStop = null;
        if (stops && Array.isArray(stops)) {
            for (let i = 0; i < stops.length; i++) {
                var stop = stops[i];
                if (stop == thisStop) {
                    started = true;
                }
                else if (started) {
                    if (stop.companyId != null && nextStop == null) {
                        if (i < stops.length - 1) {
                            // Need to check the next stop too and make sure it's not a waypoint.
                            for (let j = i+1; j < stops.length; j++) {
                                var nextStop = stops[j];
                                if (nextStop.companyId != null) {
                                    nextStop = stop;
                                    break;
                                }
                            }
                        }
                        else {
                            nextStop = stop;
                        }
                    }
                }
            }
        }
        return nextStop;
    }

    return legs?.map((leg, index) => (

        <table className="leg-table" key={index}>
            <thead>
                <tr>
                    {stops[index].companyId ? (
                        <th className="leg-table-header" colSpan="3">
                            <LegTitle legNumber={getLegNumber(stops,stops[index])} />
                            <LegAddress label="from:" stop={stops[index]} />
                            <LegAddress label="to:" stop={getNextStop(stops, stops[index])} />
                        </th>
                    ) : (
                        <td />
                    )}

                </tr>
            </thead>
            <tbody>
                <ReportLines lines={leg.reportLines} />
                <CompanyNotes stop={stops[index + 1]} />
            </tbody>
            <tfoot>{index < legs.length && <LegSeparator />}</tfoot>
        </table>
    )) ?? null
};
export default ReportLegs;
