import React, { FC } from "react";
import { Popup, ToolbarItem } from "devextreme-react/popup";

interface Props {
  popupVisible?: boolean;
  hideInfo?: (e: any) => void;
  submitButtonOptions?: any;
  closeButtonOptions?: any;
  title?: string;
  width?: number;
  height?: number;
}

const PopupComponent: FC<Props> = ({
  popupVisible,
  hideInfo,
  submitButtonOptions,
  closeButtonOptions,
  title,
  children,
  width,
  height,
}) => {

  return (
    <Popup
      visible={popupVisible}
      onHiding={hideInfo}
      dragEnabled={false}
      showCloseButton={false}
      showTitle={true}
      title={title}
      container=".dx-viewport"
      width={width}
      height={height}
    >
      {submitButtonOptions && (
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="before"
          options={submitButtonOptions}
        />
      )}

      <ToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="after"
        options={closeButtonOptions}
      />
      {children && children}
    </Popup>
  );
};

export default PopupComponent;
