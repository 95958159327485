import React, { FC, useEffect, useState } from 'react';
import 'devextreme/data/odata/store';
import DataGrid, { Column, Pager, Paging, FilterRow, Export } from 'devextreme-react/data-grid';
import './reconcile-detail.scss';
import { useAuthentication, useReconcileService } from 'services';

type Props = {
    selectedLoadNumber: string,
    selectedBeginDate: string,
    selectedEndDate: string
}

const ReconcileDetail: FC<Props> = ({ selectedLoadNumber, selectedBeginDate, selectedEndDate }) => {

    const { getByDriver, getByLoad } = useReconcileService();
    const { getUserClaim } = useAuthentication()
    const [dataSource, setDataSource] = useState();
    const [showErrorRow, setShowErrorRow] = useState(true);
    const [noDataMessage, setNoDataMessage] = useState("No data")

    useEffect(() => {
        var noData = "";
        setNoDataMessage(noData);
        async function getDataSource() {
            let driverId: any = await getUserClaim('driverId')
            let results: any = [];
            if (selectedLoadNumber && selectedLoadNumber.length > 0) {
                results = await getByLoad(driverId, selectedLoadNumber);
            }
            else if ((selectedBeginDate && selectedBeginDate.length > 0) && (selectedEndDate && selectedEndDate.length > 0)) {
                results = await getByDriver(driverId, selectedBeginDate, selectedEndDate);
            }
            setDataSource(results);
            let noData = "There were no customer reconcile billing issues with your loads during this time period. Nice Work!!";
            if (selectedLoadNumber != null && selectedLoadNumber.length > 0) {
                noData = "There were no customer reconcile billing issues with the provided load number. Nice Work!!";
            }
            setNoDataMessage(noData);
        }
        getDataSource();
    }, [selectedLoadNumber, selectedBeginDate, selectedEndDate])

    function errorHandler (e:any) {
        let errorMessage: Error = e.error;
        if (errorMessage && errorMessage.message && errorMessage.message.indexOf("E1042") >= 0) {
            setShowErrorRow(false);
        }
        else {
            setShowErrorRow(true);
        }
    }

    return (
        <div className="shadow">
            <DataGrid
                key={'id'}
                className={'dx-card wide-card'}
                dataSource={dataSource}
                showBorders={false}
                focusedRowEnabled={true}
                focusedRowKey={false}
                columnAutoWidth={true}
                columnHidingEnabled={false}
                onDataErrorOccurred={errorHandler}
                errorRowEnabled={showErrorRow}
                showRowLines={true}
                showColumnLines={true}
                noDataText={noDataMessage}
            >
                <Export enabled={true} fileName={"DriverReconcile"} />
                <Paging enabled={true} defaultPageSize={10} />
                <Pager showPageSizeSelector={true} showInfo={true} allowedPageSizes={[10, 20, 50]} />
                <FilterRow visible={true} />

                <Column
                    dataField={'reasonDescription'}
                    width={250}
                    caption={'Reason'}
                />
                <Column
                    dataField={'itemNumber'}
                    width={80}
                    caption={'Item #'}
                >
                </Column>
                <Column
                    dataField={'itemDescription'}
                    width={250}
                    caption={'Item Description'}
                />
                <Column
                    dataField={'partnerName'}
                    width={200}
                    caption={'Customer'}
                />
                <Column
                    dataField={'loadNumber'}
                    width={90}
                    caption={'Load #'}
                />
                <Column
                    dataField={'stopNumber'}
                    width={70}
                    caption={'Stop #'}
                />
                <Column
                    dataField={'loadDate'}
                    width={100}
                    caption={'Load Date'}
                    dataType={'date'}
                />
                <Column
                    dataField={'reconciler'}
                    caption={'Reconciler'}
                />
            </DataGrid>
        </div>
    );

};

export default ReconcileDetail