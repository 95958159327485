import React, { Fragment } from "react";
import Box, { Item } from "devextreme-react/box";
import "./stops.scss";
import { faMapMarkerAlt, faGreaterThan } from '@fortawesome/pro-solid-svg-icons' //more icons at: https://fontawesome.com/icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ActionButton } from 'components'
import { useHistory } from 'react-router'

let dateFormat = require('dateformat')

export default ({ stops, loadNumber }) => {

    const history = useHistory();

    function setMoreDetailsVisible(stopId) {
        history.push('/stop-details?loadNumber=' + loadNumber + '&stopId=' + stopId);
    }

    return (
        <Fragment>
            {
                stops.map((stop, index) => (
                    <Box key={stop.id} width={"100%"} className="rect stops-our-color dividerline-margins ">
                        <Item ratio={1}>
                            <div className="center-icon">
                                {
                                    index === 0 ?
                                        <FontAwesomeIcon icon={faMapMarkerAlt} className={"stops-map-marker"} size={'2x'} />
                                        :
                                        <div className={"stops-event-text"}>
                                            {stop.event}
                                        </div>
                                }
                            </div>
                            {
                                (stop.orderType == "STC" || stop.orderType == "STCORC") ?
                                    <div className="center-icon">
                                        <div className={"stops-event-text red-component"}>
                                            {"STC"}
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                            {
                                (stop.orderType == "CURETU") ?
                                    <div className="center-icon">
                                        <div className={"stops-event-text red-component"}>
                                            {"RTN"}
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                        </Item>
                        <Item ratio={5}>
                            <div className={"stops-our-stops block stops-company-name"}>{stop.companyName}</div>
                            <div className={"stops-our-stops block"}>{stop.address1}</div>
                            <div className={"stops-address"}>
                                <div className={"stops-our-stops block"}>{stop.city}, {stop.state} {stop.zip}</div>
                                <div className={"stops-mileage block"}>({stop.legMileage} miles)</div>
                            </div>
                            <div className={"stops-our-stops block stops-due-out"}>{(stop && stop.appointmentTime) && dateFormat(stop.appointmentTime, 'mm/dd - HH:MM')} {stop.appointmentTimeZone}</div>

                        </Item>
                        <Item ratio={.5}>
                            {
                                index === 0 ?
                                    <div>

                                    </div>
                                    :
                                    <div className={"vertical-center right"}>
                                        <ActionButton buttonWidth={0} icon={faGreaterThan} buttonColor={"#11111100"} iconColor={"#0066A4"} onClick={() => setMoreDetailsVisible(stop.id)} />
                                    </div >
                            }
                        </Item>
                    </Box>
                ))
            }
        </Fragment>
    );
};