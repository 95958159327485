export const applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];

  export const saleAmountHeaderFilter = [
    {
      text: "Less than $50",
      value: ["requestedAmount", "<", 50],
    },
    {
      text: "$50 - $100",
      value: [
        ["requestedAmount", ">=", 50],
        ["requestedAmount", "<", 100],
      ],
    },
    {
      text: "$100 - $500",
      value: [
        ["requestedAmount", ">=", 100],
        ["requestedAmount", "<", 500],
      ],
    },
    {
      text: "$500 - $1000",
      value: [
        ["requestedAmount", ">=", 500],
        ["requestedAmount", "<", 1000],
      ],
    },
    {
      text: "Greater than $1000",
      value: ["requestedAmount", ">=", 1000],
    },
  ];

  export const getOrderDay = (rowData) => {
    return new Date(rowData.OrderDate).getDay();
  };

  export const calculateFilterExpression = (
    value,
    selectedFilterOperations,
    target
  ) => {
    const column= {};
    if (target === "headerFilter" && value === "weekends") {
      return [[getOrderDay, "=", 0], "or", [getOrderDay, "=", 6]];
    }
    return column.defaultCalculateFilterExpression(
      value,
      selectedFilterOperations,
      target
    );
  };

  export const orderHeaderFilter = (data) => {
    data.dataSource.postProcess = (results) => {
      results.push({
        text: "Weekends",
        value: "weekends",
      });
      return results;
    };
  };