import React, { FC, useMemo } from "react";
import { Button } from "devextreme-react/button";
import Form, { SimpleItem, GroupItem, Label } from "devextreme-react/form";
import { RequiredRule } from "devextreme-react/data-grid";

interface Props {
  submitExpenseForm?: any;
  positionOptions?: any;
  selectedFile?: any;
  handleClearImage?: () => void;
  handleImageUpload?: (e: any) => void;
}

const SubmitExpenseForm: FC<Props> = ({
  submitExpenseForm,
  positionOptions,
  selectedFile,
  handleClearImage,
  handleImageUpload,
}) => {
  const handleExpenseType = useMemo(() => {
    return {
      ...positionOptions,
      searchEnabled: true,
    };
  }, [positionOptions]);

  const renderImageComponent = () => {
    return (
      <div id="img-upload-div">
        {selectedFile && selectedFile.length > 0 ? (
          <div className="">
            <Button icon={"image"} disabled={true} />
            <Button
              icon={"trash"}
              disabled={false}
              hint="Clear the Uploaded Image"
              onClick={handleClearImage}
            />
          </div>
        ) : (
          <div>
            <label id="img-upload-label" htmlFor="file">
              Upload
            </label>
            <input
              onChange={handleImageUpload}
              type="file"
              accept="*/*"
              name="file"
              id="file"
              className="inputfile"
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <Form
      colCount={"auto"}
      style={{ maxWidth: "500px" }}
      formData={submitExpenseForm}
    >
      <GroupItem colCount={"auto"}>
        <SimpleItem
          dataField="description"
          editorType="dxSelectBox"
          editorOptions={handleExpenseType}
        >
          <Label text="Expense Type" />
          <RequiredRule />
        </SimpleItem>
        <SimpleItem
          dataField="requestedAmount"
          editorType="dxNumberBox"
          editorOptions={{ readOnly: false }}
        >
          <RequiredRule />
        </SimpleItem>
        <SimpleItem
          dataField="receiptDate"
          editorType="dxDateBox"
          editorOptions={{ max: new Date(), readOnly: false }}
        >
          <RequiredRule />
        </SimpleItem>
        <SimpleItem
          dataField="comment"
          editorOptions={{ max: new Date(), readOnly: false }}
        >
          <RequiredRule />
        </SimpleItem>
      </GroupItem>
      <SimpleItem component={renderImageComponent}>
        <Label text={"Receipt Upload"} />
      </SimpleItem>
    </Form>
  );
};

export default SubmitExpenseForm;
