import { withNavigationWatcher } from './contexts/navigation';
import { HomePage, PreferredRoute, LoadConfirmation, Reconcile, StopDetails,ExpenseApproval } from './pages';
import { CreateExpense } from './components/CreateExpenseComponent'
import { Logout } from './components/okta';


const routes = [
    {
        path: '/home',
        component: HomePage,
        title: 'DTI Driver'
    },
    //Note: 05/04/2023 Preferred Route changed to Designated Router for UI's only
    {
        path: '/preferredRoute',
        component: PreferredRoute,
        title: 'Designated Route'
    },
    {
        path: '/driverPortal',
        component: () => (window.location = `${process.env.REACT_APP_DRIVER_PORTAL_PATH}`),
        title: 'DTI Portal'
    },
    {
        path: '/load-confirmation',
        component: LoadConfirmation,
        title: 'Loads'
    },
    {
        path: '/stop-details',
        component: StopDetails,
        title: 'Stop Details',
        parentPath: '/load-confirmation'
    },
    {
        path: '/reconcile',
        component: Reconcile,
        title: 'Reconcile Accuracy'
    },
    {
        path: '/logout',
        component: Logout,
        title: 'Logout'
    },
    {
        path: '/expenseapproval',
        component: ExpenseApproval,
        title: 'P.E. TEST DO NOT USE'
    },
    {
        path:'/createexpense',
        component:CreateExpense,
        title:'Create Expense'
    }
];

export default routes.map((route) => {
    return {
        ...route,
        component: withNavigationWatcher(route.component),
    };
});

