import React, { FC } from "react";
import { LoadPanel } from "devextreme-react/load-panel";

interface Props {
  setState?: (data: any) => void;
  state?: any;
  positionId?: string;
  loadPanelVisible?: boolean;
  showIndicator?: boolean;
  shading?: boolean;
  showPane?: boolean;
  hideLoadPanel?: () => void;
}

const LoadPanelComponent: FC<Props> = ({
  hideLoadPanel,
  loadPanelVisible,
  showIndicator,
  positionId,
  shading,
  showPane,
}) => {
  return (
    <LoadPanel
      shadingColor="rgba(0,0,0,0.4)"
      position={{ of: positionId }}
      onHiding={hideLoadPanel}
      visible={loadPanelVisible}
      showIndicator={showIndicator}
      shading={shading}
      showPane={showPane}
    />
  );
};

export default LoadPanelComponent;
