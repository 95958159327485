import { useAuthentication } from 'services'
import { createStore } from 'devextreme-aspnet-data-nojquery'

const useReconcileService = () => {

    const { getAuthHeader } = useAuthentication()
    const service = `${process.env.REACT_APP_DISPATCH_SERVICE}`;

    const getByDriver = async function (driverId: string, beginDate: string, endDate: string): Promise<any> {

        let headers = await getAuthHeader();
        return {
            store: createStore({
                key: 'id',
                loadUrl: `${service}/paged/reconcile/byDriver?driverId=${driverId}&beginDate=${beginDate}&endDate=${endDate}`,
                onBeforeSend: (method, ajaxOptions) => {
                    ajaxOptions.headers = headers;
                }
            })
        };
    }

    const getByLoad = async function (driverId: string, loadNumber: string): Promise<any> {

        let headers = await getAuthHeader();
        return {
            store: createStore({
                key: 'id',
                loadUrl: `${service}/paged/reconcile/byLoad?driverId=${driverId}&loadNumber=${loadNumber}`,
                onBeforeSend: (method, ajaxOptions) => {
                    ajaxOptions.headers = headers;
                }
            })
        };
    }

    return {
        getByDriver: getByDriver,
        getByLoad: getByLoad
    }
}

export default useReconcileService