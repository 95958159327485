import React, { FC } from "react";
import { Button } from "devextreme-react/button";

interface Props {
  text?: string;
  handleClick?: (e?: any) => void;
  type?: any;
  stylingMode?: any;
  width?: number;
  disabled?: boolean;
  id?: string;
}

const ButtonComponent: FC<Props> = ({
  text,
  handleClick,
  type,
  stylingMode,
  width,
  disabled,
  id,
}) => {
  return (
    <Button
      width={width}
      text={text}
      type={type}
      stylingMode={stylingMode}
      onClick={handleClick}
      disabled={disabled}
      elementAttr={{ id: id }}
    />
  );
};

export default ButtonComponent;
